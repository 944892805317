import React from 'react';
import '../src/css/Table.css';
import { Routes, Route  } from 'react-router-dom';
import { Login } from './components/Login';
import { Dashboard } from './components/Dashboard';
import { Landingpage } from './components/Landingpage';
import { Nomatch } from './components/Nomatch';
import { Auth } from './Auth';
import { Authlogin } from './Authlogin';
import { Home } from './components/Home';
import { Rates } from './components/Rates';
import { Dash } from './components/Defaultdash';
import { Slots} from './components/Slots';
import { Orders } from './components/Orders';
import { Customers } from './components/Customers';
import { Agents } from './components/Agents';
import { Dealers } from './components/Dealers';
import { Locate } from './components/Location';
import { Reports } from './components/Reports'; 
import { Products } from './components/Products';
import { Contact } from './components/Contact';
import OrderView from './components/OrderView';
import TermCondition from './components/TermCondition';
import Privacypolicy from './components/Privacypolicy';
import DataDeletion from './components/DataDeletion';
import ItemCategory from './components/ItemCategory';
import PushNotification from './components/PushNotification';
import OrgDash from './components/OrgDash';
import SellScrap from './components/SellScrap';
import ItemPage from './components/ItemPage';
import OrgLog from './components/OrgLog';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import Cities from './components/Cities';
import PrintReceipt from './components/PrintReceipt';
import DynamicSlot from './components/DynamicSlot';
import UpiUpdate from './components/UpiUpdate';
import Refcode from './components/Refcode';
import CustomerLogin from './components/CustomerLogin';
import CustomerDetails from './components/CustomerDetails';
import BsCustomer from './components/BsCustomer';
import BsOrder from './components/BsOrder';
import AgentTracking from './components/AgentTracking';
import OrderSettlement from './components/OrderSettlement';
import CustomerAllData from './components/CustomerAllData';
import Settingpage from './components/Settingpage';



function App() {
  return (
    <Routes>
      <Route path='/orgdash' element={<OrgDash/>}/>
      <Route path='/businessCustomer' element={<BsCustomer/>}/>
      <Route path='/businessCustomer/:id' element={<BsOrder/>}/>
      <Route path='/' element={<OrgLog/>}/>
      <Route path="/land" element={<Landingpage />} >
        <Route path='' element={<Home/>}/>
        <Route path='rates' element={<Rates/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='term-services' element={<TermCondition/>}/>
        <Route path='privacypolicy' element={<Privacypolicy/>}/>
        <Route path='datadeletion' element={<DataDeletion/>}/>
      </Route>
      <Route path="/admin" element={<Authlogin><Login /></Authlogin>} />
      <Route path='/dashboard' element={<Auth><Dashboard/></Auth>}>
        <Route path='' element={<Dash/>} exact/>
        <Route path='products' element={<Products/>}/>
        <Route path='slots' element={<Slots/>}/>
        <Route path='orders' element={<Orders/>} exact/>
        <Route path='customers' element={<Customers/>}/>
        <Route path='customers/:id/:mle' element={<CustomerAllData/>}/>
        <Route path='agents' element={<Agents/>}/>
        <Route path='dealers' element={<Dealers/>}/>
        <Route path='reports' element={<Reports/>}/>
        <Route path='location' element={<Locate/>}/>
        <Route path='orders/:id' element={<OrderView/>}/>
        <Route path='itemcategory' element={<ItemCategory/>}/>
        <Route path='pushnotify' element={<PushNotification/>}/>
        <Route path='sellscrap' element={<SellScrap/>}/>
        <Route path='sellscrap/itempage' element={<ItemPage/>}/>
        <Route path='events' element={<Events/>}/>
        <Route path='events/:id' element={<EventDetails/>}/>        
        <Route path='cities' element={<Cities/>}/>
        <Route path='dynamicslots' element={<DynamicSlot/>}/>
        <Route path='upiupdate' element={<UpiUpdate/>}/>
        <Route path='orders/settlement' element={<OrderSettlement/>}/>
        <Route path='setting' element={<Settingpage/>}/>
      </Route>
      <Route path='dashboard/orders/:id/:id/print' element={<PrintReceipt/>}/>
      <Route path='refcode/:code' element={<Refcode/>}/>
      <Route path='referrals' element={<CustomerLogin/>}/>       
      <Route path='referrals/details' element={<CustomerDetails/>}/>      
      <Route path='trackagent' element={<AgentTracking/>}/>
      <Route path='/*' element={<Nomatch/>}/>
    </Routes>
  );
}

export default App;
