import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL_API, IMG_URL } from '../environment'
import loader from '../img/loader1.gif'

export const Rates = () => {

  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(BASE_URL_API + 'items/active')
      .then((res) => {
        setItem(res.data);
        setLoading(false); 
      })
      .catch((err) => console.log(err));
  }, []);

    return (
      <>
        <div className="container-fluid bg-success py-5 bg-header ">
            <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Prices</h1>
              <Link className="h5 text-white" to="/">
                Home
              </Link>
              <i className="text-white px-2 text-bold">/</i>
              <Link className="h5 " style={{color:'#73be44'}} to="/rates">
                Pricelist
              </Link>
            </div>
          </div>
        </div>
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
              <h5 className="fw-bold text-uppercase" style={{color:'#73be44'}}>
                Pricing Plans
              </h5>
              <h1 className="mb-0">We are Offering Competitive Prices</h1>
            </div>

           
            <div className="row g-0 ">
              {item.map((item, index) => (
                <div
                key={item._id}
                className={`col-lg-4 wow slideInUp mb-4 shadow ${index % 2 === 0 ? "bg-white" : "bg-light"} `}
                data-wow-delay="0.6s"
              >
                <div className="rounded text-center">
                  <div className="border-bottom py-4 px-5 mb-4">
                    <h4 className=" mb-3" style={{color:'#73be44'}}>{item.item_name}</h4>
                    
                    <img
  src={IMG_URL + item.imageURL}
  className="rounded-circle p-1"
  style={{
    height: "100px",
    width: "100px",
    borderWidth: "5px",  
    borderColor: "#73be44", 
    borderStyle: "double", 
  }}
/>
                      
                  </div>
                  <div className="p-5 pt-0">
                    <h4 className="mb-3">
                     <span style={{color:'#73be44'}} >₹ </span> 
                      {item.price.toFixed(2)}
                      <small style={{color:'#73be44'}} > /{item.unit}</small>
                    </h4>
                  </div>
                </div>
              </div>
              ))}
            </div>
           
          </div>
        </div>
      </>
    );
}



