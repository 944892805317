import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import '../css/dashboard.css'
import logo from '../img/sidebarLogo.svg'

export const Dashsidebar = () => {
  
    const location = useLocation();
  const isLogout = () => {
    localStorage.removeItem('token');
  }

//   const sidehigh = () => {
//     let nav = document.querySelector('.navigation ul li');
//     nav.classList.toggle('active');
// }
  return (
  <div className="navigation">
            <ul>
                <li>
                <img
                src={logo}
                className='m-1'
                // className="bg-white"
                // style={{
                //   width: "50px",
                //   height: "50px",
                //   borderWidth: "5px",
                //   borderColor: "green",
                //   borderStyle: "double",
                // }}
              />
                {/* <Link  to="" title='RecyclePay'> */}
                {/* <div className='d-flex px-lg-2 px-md-0 px-sm-0 pt-lg-2 pt-md-0 pt-sm-0'>
            <span>
              <img
                src={logo1}
                className="rounded-circle p-1 bg-white"
                style={{
                  width: "50px",
                  height: "50px",
                  borderWidth: "5px",
                  borderColor: "green",
                  borderStyle: "double",
                }}
              />
            </span>
            &nbsp;
            <span> */}
            {/* <h2 className="text-white mx-auto mt-2 mb-0">Recycle <span style={{color:'#73be44'}}>Pay</span></h2> */}
            {/* </span>
          </div> */}
                {/* </Link> */}
                </li>

{/* sidebar button and link to pages */}
                <li>
                <Link to="" title='Dashboard'  className={`${location.pathname === '/dashboard' || location.pathname === '/dashboard/' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-house"></i></span>
                    <span className="title">Dashboard</span>
                </Link>
                </li>

                <li>
                <Link to="itemcategory" title='Productscat' className={`${location.pathname === '/dashboard/itemcategory' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-code-branch" ></i></span>
                    <span className="title">Product Category</span>
                </Link>  
                </li>
                <li>
                <Link  to="cities" title='City & Percentage' className={`${location.pathname === '/dashboard/cities' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-city"></i></span>
                    <span className="title">City & Percentage</span>
                    </Link>
                </li>
                <li>
                <Link to="products" title='Products' className={`${location.pathname === '/dashboard/products' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-table-list" ></i></span>
                    <span className="title">Products</span>
                </Link>  
                </li>

                <li>
                <Link to="slots" title='Slots' className={`${location.pathname === '/dashboard/slots' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-calendar-days"></i></span>
                    <span className="title">Slots</span>
                </Link>
                </li>
                <li>
                <Link to="dynamicslots" title='Dynamic Slots' className={`${location.pathname === '/dashboard/dynamicslots' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-calendar-days"></i></span>
                    <span className="title">Dynamic Slots</span>
                </Link>
                </li>
                <li>
                <Link to="orders" title='Orders' className={`${location.pathname === '/dashboard/orders' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-list-check"></i></span>
                    <span className="title">Orders</span>
                </Link>
                </li>
                <li>
                <Link  to="customers" title='Customers' className={`${location.pathname === '/dashboard/customers' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-users"></i></span>
                    <span className="title">Customers</span>
                    </Link>
                </li>
                <li>
                <Link  to="upiupdate" title='Upi Update' className={`${location.pathname === '/dashboard/upiupdate' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-bank"></i></span>
                    <span className="title">Upi Update</span>
                    </Link>
                </li>
                <li>
                <Link  to="agents" title='Agents' className={`${location.pathname === '/dashboard/agents' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-user-pen"></i></span>
                    <span className="title">Agents</span>
                    </Link>
                </li>
                <li>
                <Link  to="dealers" title='Dealers' className={`${location.pathname === '/dashboard/dealers' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-building"></i></span>
                    <span className="title">Dealers</span>
                    </Link>
                </li>
                <li>
                <Link  to="location" title='Location' className={`${location.pathname === '/dashboard/location' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-location-dot"></i></span>
                    <span className="title">Service Location</span>
                    </Link>
                </li>
                <li>
                <Link  to="pushnotify" title='Reports' className={`${location.pathname === '/dashboard/pushnotify' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-bell"></i></span>
                    <span className="title">Push Notification</span>
                    </Link>
                </li>
                <li>
                <Link  to="events" title='Reports' className={`${location.pathname === '/dashboard/events' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-bell"></i></span>
                    <span className="title">Events</span>
                    </Link>
                </li>
                <li>
                <Link  to="sellscrap" title='SellScrap' className={`${location.pathname === '/dashboard/sellscrap' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-shopping-cart"></i></span>
                    <span className="title">Book Order</span>
                    </Link>
                </li>
                <li>
                <Link  to="reports" title='Reports' className={`${location.pathname === '/dashboard/reports' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-file"></i></span>
                    <span className="title">Reports</span>
                    </Link>
                </li>
                <li>
                <Link  to="setting" title='Setting' className={`${location.pathname === '/dashboard/setting' ? "active" : ""}`}>  
                    <span className="icon"><i className="fa-solid fa-gear"></i></span>
                    <span className="title">Settings</span>
                    </Link>
                </li>
                <li>
                <Link onClick={isLogout} to="/admin">  
                    <span className="icon"><i className="fa-solid fa-right-from-bracket"></i></span>
                    <span className="title" >Log Out</span>
                    </Link>
                </li>
            </ul>
        </div>

  )
}
