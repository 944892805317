import React, { useState, useEffect } from "react";
import { Spinner, Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL_API } from "../environment";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import IconUrl from "../img/locmap.png";
import L from "leaflet";
function DynamicSlot() {
  const [addslot, setAddSlot] = useState(false);
  const [startpincode, setStartPincode] = useState("");
  const [endpincode, setEndPincode] = useState("");
  const [dumppincode, setDumpPincode] = useState("");
  const [hotpointPincode, setHotPointPincode] = useState([]);
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [dumptime, setDumptime] = useState("");
  const [hotpointTime, setHotpointTime] = useState([]);
  const [additionalHotlistFields, setAdditionalHotlistFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [selectedCityId, setSelectedCityId] = useState(
    "65d5772df1cccd1633087da7"
  );
  const [selectedAgentId, setSelectedAgentId] = useState("");
  // const [filteredPinCodes, setFilteredPinCodes] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [cityPer, setCityPer] = useState([]);
  const [citychangeforroute, setCityChangeForRoute] = useState("");
  const [cityIdchangeforroute, setCityIdChangeForRoute] = useState("");
  const [dateforroute, setDateForRoute] = useState(null);
  const [agentId, setAgentId] = useState("");
  const [agent, setAgent] = useState([]);
  const [agentdropdown, setAgentDropdown] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [markerValues, setMarkerValues] = useState({});
  const [agentColors, setAgentColors] = useState({});
  const [agentsData, setAgentsData] = useState([]);
  const fetchData = () => {
    axios
        .get(`${BASE_URL_API}routeslot/allSlotbyCity?cityid=${selectedCityId}&date=${selectedDate}&agentid=${selectedAgentId}`)
        .then((res) => {
          const groupedData = res.data.data.reduce((acc, data, index) => {
            // Assign a unique color to each agent
            const color = `hsl(${(index * 60) % 360}, 100%, 50%)`;
            setAgentColors((prevColors) => ({
              ...prevColors,
              [data.agent_id]: color,
            }));

            if (!acc[data.agent_id]) {
              acc[data.agent_id] = {
                routes: [],
                slots: [],
                color,
              };
            }

            // Push routes
            if (data.routes && data.routes.length > 0) {
              acc[data.agent_id].routes.push(data.routes);
            }

            // Push slots
            Object.keys(data.slots).forEach((pin) => {
              const slot = data.slots[pin][0];
              if (slot && slot.location && slot.location.length >= 2) {
                acc[data.agent_id].slots.push({
                  pinCode: pin,
                  latitude: slot.location[0],
                  longitude: slot.location[1],
                  times: slot.time,
                });
              }
            });

            return acc;
          }, {});

          console.log("Grouped Data by Agent", groupedData);
          setAgentsData(Object.values(groupedData));
        })
        .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        setCityPer(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(BASE_URL_API + "routeslot/getAgent/" + selectedCityId)
      .then((res) => {
        // console.log(res);
        // console.log(res.data);
        setAgent(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [selectedCityId, selectedDate, selectedAgentId]);

  const customIcon = L.icon({
    iconUrl: IconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  });
  const handleAddHotlistField = () => {
    setAdditionalHotlistFields((prevFields) => [
      ...prevFields,
      { pincode: "", time: "" },
    ]);
  };

  const handleHotlistFieldChange = (index, type, value) => {
    const updatedFields = [...additionalHotlistFields];
    updatedFields[index][type] = value;
    setAdditionalHotlistFields(updatedFields);
  };

  const formatDataForSecondAPI = (data) => {
    return Object.keys(data).map((zip) => {
      return {
        zip: zip,
        time: data[zip].slots.map((slot) => {
          return slot.replace("-", " to ");
        }),
        location: data[zip].location,
      };
    });
  };
  const handleSaveChanges = () => {
    setLoading(true);
    const hotlistPincode =
      hotpointPincode.length === 0 ? [] : [hotpointPincode];
    const hotlistTime = hotpointTime.length === 0 ? [] : [hotpointTime];

    const allHotlistPincode = [
      ...hotlistPincode,
      ...additionalHotlistFields.map((field) => field.pincode),
    ];
    const allHotlistTime = [
      ...hotlistTime,
      ...additionalHotlistFields.map((field) => field.time),
    ];

    const data = {
      start_pincode: startpincode,
      end_pincode: endpincode,
      dump_pincode: dumppincode,
      end_time: endtime,
      dump_time: dumptime,
      start_time: starttime,
      Slots: [
        "08:45-09:30",
        "09:45-10:30",
        "10:30-11:00",
        "11:00-11:30",
        "11:30-12:00",
        "12:30-13:00",
        "13:30-14:30",
        "15:00-15:45",
        "16:00-16:45",
        "17:00-17:45"
      ],
      hotlist_pincode: allHotlistPincode,
      hotlist_time: allHotlistTime,
      cityName: citychangeforroute,
      graphMap: "Khordha District, Odisha, India",
    };

    axios
      .post("https://imagedetector.ceibagreen.com/api/scehdulingAlgorithms", data)
      .then((response) => {
        console.log("response from first api",response.data);
        console.log(response.data.data);
        if (response.data) {
          const formattedData = formatDataForSecondAPI(response.data.data);
          const secondAPIBody = {
            month: dateforroute.toLocaleString("en-US", { month: "short" }),
            year: dateforroute.getFullYear().toString(),
            date: formatDate(dateforroute),
            time: formattedData,
            city: citychangeforroute,
            cityid: cityIdchangeforroute,
            agent_id: agentId,
            routes: response.data.road_path,
          };

          axios
            .post(BASE_URL_API + "routeslot/createSlot", secondAPIBody)
            .then((secondResponse) => {
              setLoading(false);
              console.log("Second API response:", secondResponse.data);
              if (secondResponse.data.isSuccess === true) {
                window.alert("Successfully Added");
                fetchData();
              } else {
                window.alert("Error Occured");
                fetchData();
              }
            })
            .catch((secondError) => {
              console.error("Error in second API:", secondError);
              setLoading(false);
              window.alert("Error Occured");
              fetchData();
            });
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        window.alert("Error Occured");
        fetchData();
      });
  };

  const handleDeleteHotlistField = (index) => {
    const updatedFields = [...additionalHotlistFields];
    updatedFields.splice(index, 1);
    setAdditionalHotlistFields(updatedFields);
  };

  const formatdateYMD = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const formatdateDMY = (dateString) => {
    const [year, month, date] = dateString.split("-");
    return `${date}-${month}-${year}`;
  };
  const handleDateChange = (date) => {
    // console.log(date);
    setSelectedDate(formatdateDMY(date));
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityPer.cities.find(
      (city) => city._id === selectedCityId
    );
    if (selectedCity) {
      axios
        .get(BASE_URL_API + "routeslot/getAgent/" + selectedCityId)
        .then((res) => {
          console.log(res);
          console.log(res.data);
          setAgentDropdown(res.data.data);
        })
        .catch((err) => console.log(err));
      setCityIdChangeForRoute(selectedCityId);
      setCityChangeForRoute(selectedCity.city);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row" style={{ height: "92vh" }}>
          <div className="col-md-7 ">
            <div className="d-flex justify-content-between">
              <div>
                <select
                  onChange={(e) => setSelectedCityId(e.target.value)}
                  value={selectedCityId}
                  className="form-select form-select-sm "
                  style={{
                    width: "150px",
                    height: "40px",
                    borderColor: "#73be44",
                  }}
                >
                  {cityPer && cityPer.cities
                    ? cityPer.cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.city}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div>
                <select
                  onChange={(e) => setSelectedAgentId(e.target.value)}
                  value={selectedAgentId}
                  className="form-select form-select-sm "
                  style={{
                    width: "150px",
                    height: "40px",
                    borderColor: "#73be44",
                  }}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Agent
                  </option>
                  {Array.isArray(agent) &&
                    agent.map((agent) => (
                      <option key={agent._id} value={agent._id}>
                        {agent.name}
                      </option>
                    ))}
                </select>
              </div>
              <input
                type="date"
                value={formatdateYMD(selectedDate)}
                onChange={(e) => handleDateChange(e.target.value)}
              />
            </div>
            <div className="myMap mt-2">
            <MapContainer
      center={[20.304, 85.8397]}
      zoom={12}
      style={{ height: "92vh" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {agentsData.map((agentData, agentIndex) => {
        const markerCounters = {};

        return (
          <React.Fragment key={agentIndex}>
            {agentData.slots.map((location, locIndex) => {
              const markerValue =
                markerCounters[location.pinCode] !== undefined
                  ? markerCounters[location.pinCode]
                  : (markerCounters[location.pinCode] = Object.keys(markerCounters).length + 1);

              const isMatch = markerValue !== "N/A";

              const customIcon = L.divIcon({
                html: `<div style="background-color: ${agentData.color}; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white; border-radius: 50%;">${markerValue}</div>`,
                iconSize: [30, 30],
              });

              const customIconNA = L.divIcon({
                html: `<div style="background-color: white; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; border-radius: 50%;">
                  <img src=${IconUrl} style="width: 20px; height: 20px;" /></div>`,
                iconSize: [30, 30],
              });

              const markerIcon = isMatch ? customIcon : customIconNA;

              return (
                <Marker
                  key={location.pinCode}
                  position={[location.latitude, location.longitude]}
                  icon={markerIcon}
                >
                  <Popup>
                    <div>
                      <p>Pin Code: {location.pinCode}</p>
                      <p>Available Slots:</p>
                      <ul>
                        {location.times.map((time, timeIndex) => (
                          <li key={timeIndex}>{time}</li>
                        ))}
                      </ul>
                    </div>
                  </Popup>
                </Marker>
              );
            })}

            {agentData.routes.map((route, routeIndex) => (
              <Polyline
                key={routeIndex}
                positions={route}
                color={agentData.color}
              />
            ))}
          </React.Fragment>
        );
      })}
    </MapContainer>
    </div>
          </div>
          <div
            className="col-md-5 bg-white p-2"
            style={{ maxHeight: "100vh", fontWeight: "12px" }}
          >
            <div className="d-flex justify-content-center mt-3 mb-3">
              <h6 style={{ color: "#73be44" }}>Create Route</h6>
            </div>
            <div className="d-flex justify-content-between px-3">
              <div>
                <select
                  id="cityDropdown"
                  className="form-select form-select-sm "
                  defaultValue=""
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  {cityPer && cityPer.cities
                    ? cityPer.cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.city}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div>
                <select
                  id="agentDropdown"
                  className="form-select form-select-sm "
                  defaultValue=""
                  onChange={(e) => {
                    setAgentId(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Select Agent
                  </option>
                  {Array.isArray(agentdropdown) &&
                    agentdropdown.map((agent) => (
                      <option key={agent._id} value={agent._id}>
                        {agent.name}
                      </option>
                    ))}
                </select>
              </div>
              <input
                type="date"
                onChange={(e) => setDateForRoute(new Date(e.target.value))}
              />
            </div>
            <div className="p-3" style={{ fontSize: "14px" }}>
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Start Pincode </Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={startpincode}
                            onChange={(e) => setStartPincode(e.target.value)}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Start Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="time"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                            required
                            timeFormat="HH:mm"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>End Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={endpincode}
                            onChange={(e) => {
                              setEndPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>End Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="time"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            onChange={(e) => {
                              setEndTime(e.target.value);
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Dump Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={dumppincode}
                            onChange={(e) => {
                              setDumpPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>Dump Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="time"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            onChange={(e) => {
                              setDumptime(e.target.value);
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>HotList Pincode</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="text"
                            placeholder="Enter Pin"
                            name="PIN"
                            value={hotpointPincode}
                            onChange={(e) => {
                              setHotPointPincode(e.target.value);
                            }}
                            // onKeyUp={handlePinBlur}
                            onKeyPress={(event) => {
                              const pattern = /[0-9]/;
                              const inputChar = String.fromCharCode(
                                event.charCode
                              );

                              if (
                                event.target.value.length === 0 &&
                                inputChar === "0"
                              ) {
                                event.preventDefault();
                              }

                              if (!pattern.test(inputChar)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={6}
                            autoFocus
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row>
                        <Col xs={6} md={5}>
                          <Form.Label>HotList Time</Form.Label>
                        </Col>
                        <Col xs={12} md={7}>
                          <Form.Control
                            type="time"
                            className="modalTextField"
                            style={{ paddingRight: "6px" }}
                            onChange={(e) => {
                              setHotpointTime(e.target.value);
                            }}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </div>
                {additionalHotlistFields.map((field, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-md-5">
                        <Form.Group controlId={`hotlistPin${index}`}>
                          <Row>
                            <Col xs={6} md={5}>
                              <Form.Label>HotList Pincode</Form.Label>
                            </Col>
                            <Col xs={12} md={7}>
                              <Form.Control
                                type="text"
                                placeholder="Enter Pin"
                                name="PIN"
                                value={field.pincode}
                                onChange={(e) =>
                                  handleHotlistFieldChange(
                                    index,
                                    "pincode",
                                    e.target.value
                                  )
                                }
                                maxLength={6}
                                autoFocus
                                required
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId={`hotlistTime${index}`}>
                          <Row>
                            <Col xs={6} md={5}>
                              <Form.Label>HotList Time</Form.Label>
                            </Col>
                            <Col xs={12} md={7}>
                              <Form.Control
                                type="time"
                                className="modalTextField"
                                style={{ paddingRight: "6px" }}
                                value={field.time}
                                onChange={(e) =>
                                  handleHotlistFieldChange(
                                    index,
                                    "time",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                      <div className="col-md-1">
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteHotlistField(index)}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex justify-content-end mt-3">
                  <Button variant="primary" onClick={handleAddHotlistField}>
                    Add HotList
                  </Button>
                </div>
              </Form>
            </div>
            <div className="d-flex justify-content-center mt-3">
              {loading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button variant="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicSlot;
